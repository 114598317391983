import { AxiosInstance } from 'axios';

/**
 * @param {AxiosInstance} api
 * @param {string} content
 * @param {string[] | 'all' | 'cashiers' | 'masters' | 'clients} recipients
 */
export async function sendNotificationToAll(api, content, recipients) {
  return (await api.post('toca/admin/sendNotificationToAll', { content: content, to: recipients }))
    .data;
}

/**
 * @param {AxiosInstance} api
 * @param {{filter?: *, limit?: number, offset?: number, page?: number, select?: string[], sort?:{}}} [params]
 * @return {Promise<{docs: *[], total: number, offset: number}>}
 */

export async function listNotification(api, options) {
  return (await api.get(`toca/admin/PushNotifications`, { params: options })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} staffId
 * @return {Promise<{docs: *[], total: number, offset: number}>}
 */

export async function listStaffNotification(api, staffId, options) {
  return (
    await api.get(`toca/admin/PushNotification/getMyNotifications/${staffId}`, { params: options })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string|string[]} ids
 * @return {Promise<*>}
 */
export async function notificationMarkAsRead(api, ids) {
  return (
    await api.get(`users/me/notifications/${ids}/read`, {
      params: {
        ids: ids.join(','),
      },
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 */
export async function checkIfHasUnreadNotification(api) {
  const res = (
    await api.get(`users/me/notifications/`, {
      params: { filter: { read: null }, limit: 0 },
    })
  ).data;
  return res?.total > 0;
}
