<template>
  <v-app>
    <v-alert class="popAlert" v-if="displayAlert" :type="alertType" :color="color">
      <div style="text-align: center">{{ alertMessage }}</div>
      <template v-slot:close>
        <v-icon @click="closeAlert"> mdi-close</v-icon>
      </template>
    </v-alert>
    <v-app-bar app flat class="whatsappGrey">
      <!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />-->
      <img src="@/assets/TocaLogo.svg" style="width: 30px; height: 30px; padding: 2.5px" />
      <!--      <v-toolbar-title>Title</v-toolbar-title>-->
      <!--      <v-menu bottom right>-->
      <!--        <template v-slot:activator="{ on, attrs }">-->
      <!--          <v-btn icon color="inherit" v-bind="attrs" v-on="on">-->
      <!--            <v-icon>mdi-dots-vertical</v-icon>-->
      <!--          </v-btn>-->
      <!--        </template>-->

      <!--        <v-list>-->
      <!--          <v-list-item v-for="(item, i) in items" :key="i">-->
      <!--            <v-list-item-title>{{ item.title }}</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--        </v-list>-->
      <!--      </v-menu>-->
      <v-spacer />
      <div class="text--secondary">
        <small>{{ buildVersion }}</small>
      </div>
      <v-spacer />
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      loginBlockedMessage: '',
      drawer: false,
    };
  },
  computed: {
    ...mapGetters('alert', ['alertType', 'displayAlert', 'alertMessage', 'color']),
    ...mapGetters('auth', ['profile', 'role', 'isLoggedIn', 'loginBlocked']),
    ...mapGetters('pendingLoginManagePanel', ['isPendingRequest']),
    buildVersion() {
      const { time, git } = process.env.BUILD;
      return `ver: ${git.version}-${git.branch} (${moment(time).format('YYYY-MM-DD HH:mm')})`;
    },
  },
  methods: {
    ...mapActions('alert', ['closeAlert']),
    ...mapActions('auth', ['checkLoginStatus']),
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog--active {
  border-radius: 20px;
}

.v-main {
  height: 100vh;
  background-color: var(--v-whatsappGrey-base);
}
.popAlert {
  position: fixed;
  bottom: 0px;
  z-index: 900;
  width: 60%;
  left: calc((100% - 60%) / 2);
}
</style>
