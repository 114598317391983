import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import defaultLayout from '@/layout/default';
import LoginPage from '@/views/LoginPage';
import authLayout from '@/layout/auth';
import vuetify, { defaults } from '@/plugins/vuetify';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: defaultLayout,
    redirect: { name: 'transactions' },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Dashboard'),
        meta: {
          auth: ['admin'],
        },
        // props: r => ({ selectedRole: r.query.role }),
      },
      {
        path: '/profiles',
        name: 'Profiles',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Identities'),
        meta: {
          auth: ['admin', 'toca.cashier'],
        },
        props: r => ({ selectedRole: r.query.role, selectedManagement: r.query.management }),
      },
      {
        path: '/profiles/:id([0-9a-zA-Z]{24})',
        name: 'Profile',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/Identities/identityDetails'),
        meta: {
          auth: ['admin', 'toca.cashier'],
        },
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      },
      {
        path: '/myProfile',
        name: 'myProfile',
        component: () => import(/* webpackChunkName: "master" */ '../views/MyProfile.vue'),
        meta: {
          auth: ['admin', 'toca.master', 'toca.cashier'],
        },
      },
      {
        path: '/serviceProducts',
        name: 'serviceProducts',
        component: () => import(/* webpackChunkName: "admin" */ '../views/ServiceProduct'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/serviceProducts/:id([0-9a-zA-Z]{24})',
        name: 'serviceProduct',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/ServiceProduct/serviceProductDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.cashier'],
        },
      },
      {
        path: '/physicalProducts',
        name: 'physicalProducts',
        component: () => import(/* webpackChunkName: "admin" */ '../views/PhysicalProduct'),
        meta: {
          auth: ['admin', 'toca.cashier'],
        },
      },
      {
        path: '/physicalProducts/:id([0-9a-zA-Z]{24})',
        name: 'physicalProduct',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/PhysicalProduct/PhysicalProductDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.cashier'],
        },
      },
      // {
      //   path: '/restaurantProducts',
      //   name: 'restaurantProducts',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/RestaurantProduct'),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      // {
      //   path: '/restaurantProducts/:id([0-9a-zA-Z]{24})',
      //   name: 'restaurantProduct',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "admin" */ '../views/RestaurantProduct/RestaurantProductDetails'
      //     ),
      //   props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      // {
      //   path: '/restaurantProducts/new',
      //   name: 'restaurantProductsNew',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "admin" */ '../views/RestaurantProduct/RestaurantProductDetails'
      //     ),
      //   props: r => ({ id: 'new', editMode: !!r.query.edit }),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      // {
      //   path: '/booking',
      //   name: 'bookings',
      //   component: () => import(/* webpackChunkName: "master" */ '../views/Booking'),
      //   meta: {
      //     auth: ['admin', 'toca.cashier'],
      //   },
      // },
      {
        path: '/transaction',
        name: 'transactions',
        component: () => import(/* webpackChunkName: "master" */ '../views/Transaction'),
        meta: {
          auth: ['admin', 'toca.cashier'],
        },
      },
      {
        path: '/transaction/:id([0-9a-zA-Z]{24})',
        name: 'transaction',
        component: () =>
          import(/* webpackChunkName: "master" */ '../views/Transaction/TransactionDetail'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.master', 'toca.cashier'],
        },
      },
      // {
      //   path: '/clockInRecords',
      //   name: 'clockInRecords',
      //   component: () => import(/* webpackChunkName: "master" */ '../views/ClockInRecord'),
      //   meta: {
      //     auth: ['admin', 'toca.cashier', 'toca.master'],
      //   },
      // },
      // {
      //   path: '/booking/:id([0-9a-zA-Z]{24})',
      //   name: 'booking',
      //   component: () => import(/* webpackChunkName: "master" */ '../views/Booking/bookingDetails'),
      //   props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      //   meta: {
      //     auth: ['admin', 'toca.master', 'toca.cashier'],
      //   },
      // },
      // {
      //   path: '/timetable',
      //   name: 'timetables',
      //   component: () => import(/* webpackChunkName: "master" */ '../views/Timetable'),
      //   meta: {
      //     auth: ['admin', 'toca.master', 'toca.cashier'],
      //   },
      // },
      // {
      //   path: '/coachesTimetable',
      //   name: 'CoachesTimetables',
      //   component: () => import(/* webpackChunkName: "master" */ '../views/Timetable'),
      //   props: r => ({ isCoachOnly: true }),
      //   meta: {
      //     auth: ['admin', 'toca.master', 'toca.cashier'],
      //   },
      // },
      // {
      //   path: '/restaurantTable',
      //   name: 'restaurantTables',
      //   component: () => import(/* webpackChunkName: "master" */ '../views/RestaurantTable'),
      //   meta: {
      //     auth: ['admin', 'toca.master'],
      //   },
      // },
      // {
      //   path: '/restaurantTable/:id',
      //   name: 'restaurantTable',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "master" */ '../views/RestaurantTable/RestaurantTableDetails'
      //     ),
      //   props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      //   meta: {
      //     auth: ['admin', 'toca.master'],
      //   },
      // },
      // {
      //   path: '/restaurantTable/new',
      //   name: 'restaurantTableNew',
      //   component: () =>
      //     import(/* webpackChunkName: "admin" */ '../views/RestaurantTable/RestaurantTableDetails'),
      //   props: r => ({ id: 'new', editMode: !!r.query.edit }),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      {
        path: '/store',
        name: 'stores',
        component: () => import(/* webpackChunkName: "master" */ '../views/Store'),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/store/:id',
        name: 'store',
        component: () => import(/* webpackChunkName: "master" */ '../views/Store/StoreDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/store/new',
        name: 'storeNew',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Store/StoreDetails'),
        props: r => ({ id: 'new', editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/warehouse',
        name: 'warehouses',
        component: () => import(/* webpackChunkName: "master" */ '../views/Warehouse'),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/warehouse/:id',
        name: 'warehouse',
        component: () =>
          import(/* webpackChunkName: "master" */ '../views/Warehouse/WarehouseDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/warehouse/new',
        name: 'warehouseNew',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/Warehouse/WarehouseDetails'),
        props: r => ({ id: 'new', editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/stockCountRecord',
        name: 'stockCountRecords',
        component: () => import(/* webpackChunkName: "master" */ '../views/StockCountRecord'),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/stockCountRecord/:id',
        name: 'stockCountRecord',
        component: () =>
          import(
            /* webpackChunkName: "master" */ '../views/StockCountRecord/StockCountRecordDetails'
          ),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/receiveProducts',
        name: 'receiveProducts',
        component: () => import(/* webpackChunkName: "admin" */ '../views/ReceiveProduct/index'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/stockTransferProblem',
        name: 'stockTransferProblem',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/StockTransferProblem/index'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/supplier',
        name: 'suppliers',
        component: () => import(/* webpackChunkName: "master" */ '../views/Supplier'),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/supplier/:id',
        name: 'supplier',
        component: () =>
          import(/* webpackChunkName: "master" */ '../views/Supplier/SupplierDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/purchase-order',
        name: 'purchase-orders',
        component: () => import(/* webpackChunkName: "master" */ '../views/PurchaseOrder'),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/purchase-order/:id',
        name: 'purchase-order',
        component: () =>
          import(/* webpackChunkName: "master" */ '../views/PurchaseOrder/PurchaseOrderDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/supplier/new',
        name: 'supplierNew',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/Supplier/SupplierDetails'),
        props: r => ({ id: 'new', editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/discount',
        name: 'discounts',
        component: () => import(/* webpackChunkName: "master" */ '../views/Discount'),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/discount/:id',
        name: 'discount',
        component: () =>
          import(/* webpackChunkName: "master" */ '../views/Discount/DiscountDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.master'],
        },
      },
      {
        path: '/discount/new',
        name: 'discountNew',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/Discount/DiscountDetails'),
        props: r => ({ id: 'new', editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/productCategory',
        name: 'productCategories',
        component: () => import(/* webpackChunkName: "admin" */ '../views/ProductCategory'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/productCategory/:id([0-9a-zA-Z]{24})',
        name: 'productCategory',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/ProductCategory/ProductCategoryDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/Notification',
        name: 'Notification',
        component: () => import(/* webpackChunkName: "master" */ '../views/Notification/index'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/clockInRecords',
        name: 'ClockInRecords',
        component: () => import(/* webpackChunkName: "master" */ '../views/ClockInRecord/index'),
        meta: {
          auth: ['admin']
        }
      },
      {
        path: '/SystemConfig',
        name: 'SystemConfig',
        component: () => import(/* webpackChunkName: "master" */ '../views/SystemConfig/index'),
        meta: {
          auth: ['admin'],
        },
      },
      // {
      //   path: '/leaveApplication',
      //   name: 'leaveApplications',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/LeaveApplication'),
      //   meta: {
      //     auth: ['admin', 'toca.master'],
      //   },
      // },
      // {
      //   path: '/leaveApplication/:id([0-9a-zA-Z]{24})',
      //   name: 'leaveApplication',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "admin" */ '../views/LeaveApplication/LeaveApplicationDetails'
      //     ),
      //   props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      //   meta: {
      //     auth: ['admin', 'toca.master'],
      //   },
      // },
      // {
      //   path: '/TeachingTask',
      //   name: 'TeachingTasks',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/TeachingTask'),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      // {
      //   path: '/TeachingTask/:id([0-9a-zA-Z]{24})',
      //   name: 'TeachingTask',
      //   component: () =>
      //     import(/* webpackChunkName: "admin" */ '../views/TeachingTask/TeachingTaskDetails'),
      //   props: r => ({ id: r.params.id, editMode: !!r.query.edit, selectedTab: r.query.tab }),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      // {
      //   path: '/EmployeeContract',
      //   name: 'EmployeeContracts',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/EmployeeContract'),
      //   meta: {
      //     auth: ['admin', 'toca.master'],
      //   },
      // },
      // {
      //   path: '/EmployeeContract/:id([0-9a-zA-Z]{24})',
      //   name: 'EmployeeContract',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "admin" */ '../views/EmployeeContract/EmployeeContractDetails'
      //     ),
      //   props: r => ({ id: r.params.id, editMode: !!r.query.edit, selectedTab: r.query.tab }),
      //   meta: {
      //     auth: ['admin', 'toca.master'],
      //   },
      // },
      // {
      //   path: '/EmployeeContract/new',
      //   name: 'EmployeeContractNew',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "admin" */ '../views/EmployeeContract/EmployeeContractDetails'
      //     ),
      //   props: r => ({ id: 'new', editMode: !!r.query.edit, selectedTab: r.query.tab }),
      //   meta: {
      //     auth: ['admin', 'toca.master'],
      //   },
      // },
      // {
      //   path: '/payroll',
      //   name: 'payrolls',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/Payroll/index'),
      //   meta: {
      //     auth: ['admin', 'toca.master', 'toca.cashier'],
      //   },
      // },
      // {
      //   path: '/payroll/:id([0-9a-zA-Z]{24})',
      //   name: 'payroll',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/Payroll/PayrollDetails'),
      //   props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      //   meta: {
      //     auth: ['admin', 'toca.master', 'toca.cashier'],
      //   },
      // },
      // {
      //   path: '/payroll/new',
      //   name: 'payrollStep',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/Payroll/new'),
      //   props: r => ({
      //     selectedStep: r.query.step,
      //     selectedDate: r.query.date,
      //     selectedMid: r.query.mid,
      //   }),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      {
        path: '/changePassword/:id([0-9a-zA-Z]{24})',
        name: 'changePassword',
        component: () =>
          import(/* webpackChunkName: "changePassword" */ '../views/Identities/changePassword'),
        props: r => ({ id: r.params.id }),
      },
      {
        path: '/changeUserName/:id([0-9a-zA-Z]{24})',
        name: 'changeUserName',
        component: () =>
          import(/* webpackChunkName: "changePassword" */ '../views/Identities/changeUserName'),
        props: r => ({ id: r.params.id }),
      },
      // {
      //   path: '/physicalProducts',
      //   name: 'physicalProducts',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/PhysicalProduct/index'),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      // {
      //   path: '/physicalProducts/:id([0-9a-zA-Z]{24})',
      //   name: 'physicalProduct',
      //   component: () =>
      //     import(/* webpackChunkName: "admin" */ '../views/PhysicalProduct/PhysicalProductDetails'),
      //   props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      //   meta: {
      //     auth: ['admin'],
      //   },
      // },
      {
        path: '/extensionProducts',
        name: 'extensionProducts',
        component: () => import(/* webpackChunkName: "admin" */ '../views/ExtensionProduct/index'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/extensionProducts/:id([0-9a-zA-Z]{24})',
        name: 'extensionProduct',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../views/ExtensionProduct/ExtensionProductDetails'
          ),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/extensionProducts/new',
        name: 'extensionProductsNew',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../views/ExtensionProduct/ExtensionProductDetails'
          ),
        props: r => ({ id: 'new', editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/physicalProductOrders',
        name: 'physicalProductOrders',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/PhysicalProductOrder/index'),
        meta: {
          auth: ['admin', 'toca.cashier', 'toca.master'],
        },
      },
      {
        path: '/orders/:id([0-9a-zA-Z]{24})',
        name: 'orders',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Order/OrderDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin', 'toca.cashier', 'toca.master'],
        },
      },
      {
        path: '/orders',
        name: 'orders',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Order/index'),
        meta: {
          auth: ['admin', 'toca.cashier', 'toca.master'],
        },
      },
      {
        path: '/vipProducts',
        name: 'vipProducts',
        component: () => import(/* webpackChunkName: "admin" */ '../views/VipProduct/index'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/vipProducts/:id([0-9a-zA-Z]{24})',
        name: 'vipProduct',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/VipProduct/VipProductDetails'),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/vipProducts/new',
        name: 'vipProductsNew',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/VipProduct/VipProductDetails'),
        props: r => ({ id: 'new', editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/vipRecords',
        name: 'vipRecords',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/VIPProductPurchaseRecord/index'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/vipRecords/:id([0-9a-zA-Z]{24})',
        name: 'vipRecord',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../views/VIPProductPurchaseRecord/VIPProductPurchaseRecordDetails'
          ),
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/vipRecords/new',
        name: 'vipRecordsNew',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../views/VIPProductPurchaseRecord/VIPProductPurchaseRecordDetails'
          ),
        props: r => ({ id: 'new', editMode: !!r.query.edit }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/vipActionLogs',
        name: 'vipActionLogs',
        component: () => import(/* webpackChunkName: "admin" */ '../views/VipActionLog/index'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/creditRecords',
        name: 'creditRecords',
        component: () => import(/* webpackChunkName: "admin" */ '../views/CreditRecord/index'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/creditRecords/:id([0-9a-zA-Z]{24})',
        name: 'creditRecordDetail',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/CreditRecord/creditRecordDetail'),
        meta: {
          auth: ['admin'],
        },
        props: r => ({ id: r.params.id }),
      },
      {
        path: '/homePage',
        name: 'homePage',
        component: () => import(/* webpackChunkName: "admin" */ '../views/CMS/homePage'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import(/* webpackChunkName: "admin" */ '../views/CMS/privacyPolicy'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/termsAndConditions',
        name: 'termsAndConditions',
        component: () => import(/* webpackChunkName: "admin" */ '../views/CMS/termsAndConditions'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/FAQ',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "admin" */ '../views/CMS/FAQ'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import(/* webpackChunkName: "admin" */ '../views/CMS/contactUs'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/coach',
        name: 'coach',
        component: () => import(/* webpackChunkName: "admin" */ '../views/CMS/coach'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/homePagePricing',
        name: 'homePagePricing',
        component: () => import(/* webpackChunkName: "admin" */ '../views/CMS/homePagePricing'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/homePageDiscoverClasses',
        name: 'homePageDiscoverClasses',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/CMS/homePageDiscoverClasses'),
        meta: {
          auth: ['admin'],
        },
      },
    ],
  },
  {
    path: '/auth',
    name: 'authHome',
    component: authLayout,
    redirect: { name: 'login' },
    children: [
      {
        path: '/auth/login',
        name: 'login',
        component: LoginPage,
        props: r => ({ ret: r.query.ret }),
      },
      {
        path: '/auth/forgetpassword',
        name: 'forgetpassword',
        component: () => import('../views/LoginPage/forgetpw.vue'),
      },
      {
        path: '/auth/changeMyPassword',
        name: 'changeMyPassword',
        component: () => import('../views/ChangeMyPassword/index'),
      },
      // {
      //   path: '/forgotpassword',
      //   name: 'Password',
      //   component: () => import('../views/LoginPage/resetpw.vue'),
      //   props: r => ({ token: r.query.token }),
      // },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.auth) {
    if (!store.getters['auth/isLoggedIn']) {
      return next({ name: 'login', query: { ret: to.fullPath } });
    }
    if (Array.isArray(to.meta.auth)) {
      if (!to.meta.auth.includes(store.getters['auth/role'])) {
        if (to.redirectedFrom === '/') {
          return next({ name: 'login', query: { ret: to.fullPath } });
        }
        return next('/');
      }
    }
  }
  next();
});
router.afterEach((to, form) => {
  vuetify.framework.theme.themes.light = { ...defaults };
  switch (store.getters['auth/role']) {
    case 'admin':
      vuetify.framework.theme.themes.light.primary = '#E1551A';
      break;
    case 'toca.cashier':
      vuetify.framework.theme.themes.light.primary = '#E1551A';
      break;
    case 'toca.master':
      vuetify.framework.theme.themes.light.primary = '#E1551A';
      break;
    default:
  }
  if (typeof to.meta?.theme === 'object' && to.meta?.theme) {
    Object.assign(vuetify.framework.theme.themes.light, to.meta.theme);
  }
});

export function canAccessRoute(to) {
  const r = router.resolve(to)?.route;
  if (!r) return false;
  const passAuthCheck =
    r && (!Array.isArray(r.meta?.auth) || r.meta.auth.includes(store.getters['auth/role']));
  const passDevCheck = r && (!r.meta?.isDevOnly || !!process.env.VUE_APP_SHOW_DEV_MENU_PATH);
  return passAuthCheck && passDevCheck;
}
