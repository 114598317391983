import { colorOption } from '@/data/optionsConstants';
import moment from 'moment';

export function getOid(v) {
  return v && v._id ? v._id : v;
}

export { getOid as getId };

export function oidEquals(a, b) {
  a = getOid(a);
  b = getOid(b);
  return a === b || (a == null && b == null) || String(a) === String(b);
}

export function openPrintWindow(id = 'print') {
  // Get HTML to print from element
  const prtHtml = document.getElementById(id).innerHTML;
  // Get all stylesheets HTML
  let stylesHtml = '';
  for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
    stylesHtml += node.outerHTML;
  }

  // Open the print window
  const WinPrint = window.open(
    '',
    '',
    'left=0,top=0,width=700,height=900,toolbar=0,scrollbars=0,status=0',
  );

  WinPrint.document.write(`<!DOCTYPE html>
<html >
  <head>
    <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
    <meta http-equiv="Pragma" content="no-cache" />
    <meta http-equiv="Expires" content="0" />
  <link rel="stylesheet" type="text/css" media="print" href="/assets/print.css">
    ${stylesHtml}
  </head>
  <body id="html">
  <script>
  var ipad = ('checkDevice',(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad')
  setTimeout(()=>{
    if(ipad){
      var element = document.getElementsByClassName("page");
      element[0].classList.add("onIpad")
      element[1].classList.add("onIpad")
      element[2].classList.add("onIpad")
    }
  },100)
  
  </script>
    ${prtHtml}
 `);

  // now you can print, need to wait for image to load - which connotes you need to work out how to wait for all content to load
  WinPrint.document.write(`</body>
</html>`);
  WinPrint.document.close();

  const html = WinPrint.document.getElementById('html');
  html.onload = function () {
    WinPrint.focus();
    WinPrint.print();
  };

  // WinPrint.onload = function () {
  //   WinPrint.focus();
  //   WinPrint.print();
  // };

  // WinPrint.document.close();

  window.onafterprint = function (event) {
    console.log('Event', event);
  };
}

export function colorProportion(cp) {
  return [formatRound(cp.firstRound), formatRound(cp.secondRound)].filter(x => x).join(' ~~> \n');
}

function formatRound(round) {
  if (!round) return '';
  return ['1', '2', '3']
    .map(x => {
      const type = round['type' + x];
      const cX = round['color' + x] || [];
      const uX = round['unit' + x] || [];
      const seq = cX.map((c, i) => c + (uX[i] || '')).join(' + ');
      const typeFormat = {
        hairFront: '(前) {seq}',
        hairTail: '(尾) {seq}',
        full: '(全) {seq}',
        hairRoot: '(根) {seq}',
        washAndDye: '洗補 {seq}',
        top: '(頂) {seq}',
      };
      return (typeFormat[type] || '{seq}').replace('{seq}', seq);
    })
    .filter(x => x)
    .join(' ');
}

export function emptyPaginationResult() {
  return { docs: [], limit: 10, offset: 0, page: 1, pages: 1, total: 0 };
}

// counts include start and end date
export function calculateWorkingDays(start, end, publicHolidayDates) {
  let count = 0;
  if (!(start && end) || !publicHolidayDates || publicHolidayDates.length === 0) {
    return null;
  }

  let startDate = moment(start).startOf('day');
  let endDate = moment(end).endOf('day');
  const publicHolidays = publicHolidayDates;

  //return if start date exceed the last day of the year provided by public holidays data
  if (endDate.isAfter(moment(publicHolidays.slice(-1)[0].start).endOf('year'))) {
    return null;
  }

  count = endDate.diff(startDate, 'days') + 1;

  for (let i = startDate; i <= endDate; i.add(1, 'days')) {
    // filter out sunday
    if (i.day() === 0) {
      count -= 1;
      continue;
    }
    // filter out public holidays
    for (let j = 0; j < publicHolidays.length - 1; j++) {
      if (moment(i).isBetween(publicHolidays[j].start, publicHolidays[j].end, null, '[)')) {
        count -= 1;
      }
    }
  }
  return isNaN(count) ? 0 : count;
}

export function calculateWorkingHours(startDate, startTime, endTime) {
  if (!(startDate && startTime && endTime)) {
    return null;
  }
  let dateStart = new Date(`${startDate} ${startTime}`);
  let dateEnd = new Date(`${startDate} ${endTime}`);
  let start = moment(dateStart);
  let end = moment(dateEnd);
  const count = end.diff(start, 'hours');

  return isNaN(count) ? 0 : count;
}

export function parseLocalizedString(v) {
  // Check if the value is already in the desired format
  if (typeof v === 'object' && v.en !== undefined && v['zh-hk'] !== undefined && v['zh-hk'] !== undefined) return v;

  const out = new Map();

  try {
    const parsedData = JSON.parse(v)
    out.set('en', parsedData.en ? parsedData.en : parsedData['zh-hk'] ? parsedData['zh-hk'] : parsedData['zh-cn'] ? parsedData['zh-cn'] : v)
    out.set('zh-hk', parsedData['zh-hk'] ? parsedData['zh-hk'] : parsedData.en ? parsedData.en : parsedData['zh-cn'] ? parsedData['zh-cn'] : v)
    out.set('zh-cn', parsedData['zh-cn'] ? parsedData['zh-cn'] : parsedData['zh-hk'] ? parsedData['zh-hk'] : parsedData.en ? parsedData.en : v)
  } catch (error) {
    out.set('en', v ? v : '')
    out.set('zh-hk', v ? v : '')
    out.set('zh-cn', v ? v : '')
  }
  // Transform the value to the LocalizeStringSchema format
  return Object.fromEntries(out)
}
