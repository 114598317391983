var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[_vm._l((_vm.routes),function(ref){
var to = ref.to;
var key = ref.key;
var icon = ref.icon;
var title = ref.title;
var children = ref.children;
var divider = ref.divider;
var inset = ref.inset;
return [(divider)?_c('v-divider',{staticStyle:{"border-color":"var(--v-primary-base)"},attrs:{"inset":inset}}):(children)?_c('v-list-group',{key:key,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(title))])]},proxy:true}],null,true)},_vm._l((children),function(child){return _c('v-list-item',{key:child.key,attrs:{"to":child.to,"exact":true,"color":"primary"}},[_c('v-list-item-action',[_c('span',{staticClass:"full-width text-center"},[_vm._v(_vm._s(child.textIcon))]),_c('v-icon',[_vm._v(_vm._s(child.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.title)}})],1)],1)}),1):_c('v-list-item',{key:key,class:{ 'v-list-item--active': _vm.$route.name === key },attrs:{"to":to,"exact":true,"color":"primary"}},[_c('v-list-item-action',[(_vm.hasUnreadNotification && title === 'Notification')?_c('v-badge',{attrs:{"color":"red","dot":""}}):_vm._e(),_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }