<template>
  <div style="margin-bottom: 8px">
    <div class="mainTitle" v-if="title">
      <span>{{ title }}</span>
    </div>
    <div class="itemFieldName" v-if="itemFieldName">
      <span>{{ itemFieldName }}</span>
    </div>
    <div class="text" v-if="text">
      <span>{{ text }}</span>
    </div>

    <div v-if="$scopedSlots.append">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'customLabel',
  props: {
    title: String,
    itemFieldName: String,
    text: String,
  },
};
</script>

<style scoped>
.text {
  color: #8898aa;
  font-size: 14px;
}
</style>
