import { listMyProfiles } from '@/api/auth';
import { ssoCheckLoginStatus } from '@/api/auth/sso';
import api from '@/api';
import { roles } from '@/data/constants';

const state = {
  allowedRoles: ['admin', 'toca.master', 'toca.cashier'], //[Check] vary by projects
  mustLogin: true,
  user: null,
  profile: null,
  profiles: [],
  /** @type {Boolean | Error} */
  loginBlocked: false,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  setProfiles(state, profiles) {
    state.profiles = Array.isArray(profiles) ? profiles : [];
  },
  setLoginBlocked(state, blocked) {
    state.loginBlocked = blocked;
  },
};

const actions = {
  async checkLoginStatus({ commit, state, dispatch }) {
    try {
      const data = await ssoCheckLoginStatus(api);
      commit('setUser', data.user);
      commit('setProfile', data.profile);
      if (!data.user && state.mustLogin) {
        throw new Error('mustLogin');
      }
      if (data.profile && !state.allowedRoles.includes(data.profile.role)) {
        throw new Error('allowedRoles');
      }
      // Temporarily not used in the project, comment to avoid fetching errors
      // try {
      //   if (data.user && data.profile.role !== roles.master) {
      //     commit('setProfiles', (await listMyProfiles(api, { limit: 50 })).docs);
      //   } else {
      //     commit('setProfiles', []);
      //   }
      // } catch (e) {
      //   commit('setProfiles', []);
      //   // console.log(e);
      // }
      return data;
    } catch (e) {
      throw e;
    }
  },
};

const getters = {
  user: state => state.user,
  profile: state => state.profile,
  profiles: state => state.profiles,
  userId: state => state.user && state.user._id,
  profileId: state => state.profile && state.profile._id,
  role: state => state.profile && state.profile.role,
  isAdmin: (state, getters) => getters.role === 'admin',
  isMaster: (state, getters) => getters.role === 'toca.master',
  isCashier: (state, getters) => getters.role === 'toca.cashier',
  // isAgent: (state, getters) => getters.role === 'agent',
  // isManager: (state, getters) => getters.role === 'manager',
  isLoggedIn: state => !!state.user,
  loginBlocked: state => state.loginBlocked,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
