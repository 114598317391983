const state = {
  storedType: null,
  storedStart: null,
  storedEnd: null,
  storedMonth: null,
};

const mutations = {
  updateStoredType(state, val) {
    state.storedType = val;
  },
  updateStoredStart(state, val) {
    state.storedStart = val;
  },
  updateStoredEnd(state, val) {
    state.storedEnd = val;
  },
  updateStoredMonth(state, val) {
    state.storedMonth = val;
  },
};

const actions = {};

const getters = {
  storedType: state => state.storedType,
  storedStart: state => state.storedStart,
  storedEnd: state => state.storedEnd,
  storedMonth: state => state.storedMonth,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
