/**
 * @param {AxiosInstance} api
 * @param {*} credentialsAndProfile
 * @return {Promise<User>}
 */
export async function registerUser(api, credentialsAndProfile) {
  return (await api.post('user/register', credentialsAndProfile)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{limit?: number, offset?: number, page?: number}} [pagination]
 * @return {Promise<{docs: Profile[], total: number, limit: number, offset: number}>}
 */
export async function listMyProfiles(api, pagination = {}) {
  return (
    await api.get('users/me/profiles', {
      params: pagination,
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{oldPW,newPw}} payload
 */
export async function changeMyPassword(api, payload) {
  return (await api.post('users/me/changePassword', payload)).data;
}
