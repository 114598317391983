export const getMenuData = [
  {
    title: '管理員 | Admin',
    icon: 'mdi-account-cog',
    key: 'admin',
    to: `/profiles?role=admin`,
    auth: ['admin'],
  },
  {
    title: '收銀員 | Cashier',
    icon: 'mdi-cash-register',
    key: 'cashier',
    to: `/profiles?role=toca.cashier`,
    auth: ['admin'],
  },
  {
    title: '顧客 | Customer',
    icon: 'mdi-account',
    key: 'client',
    to: `/profiles?role=toca.client`,
    auth: ['admin', 'toca.cashier'],
  },
  {
    title: '供應商 | Supplier',
    key: 'supplier',
    to: `/supplier`,
    icon: 'mdi-truck',
  },
  { divider: true },
  {
    title: '店舖 | Store',
    key: 'store',
    to: `/store`,
    icon: 'mdi-store',
  },
  {
    title: '倉庫 | Warehouse',
    key: 'warehouse',
    to: `/warehouse`,
    icon: 'mdi-warehouse',
  },
  {
    title: '接收產品 | Receive Products',
    key: 'receiveProducts',
    to: `/receiveProducts`,
    icon: 'mdi-hand-coin',
  },
  {
    title: '調撥異常 | Transfer Problem',
    key: 'stockTransferProblem',
    to: `/stockTransferProblem`,
    icon: 'mdi-truck-alert',
  },
  {
    title: '採購訂單 | Purchase Order',
    key: 'purchase-order',
    to: `/purchase-order`,
    icon: 'mdi-cart',
  },
  { divider: true },
  {
    title: '產品類別 |  Category',
    key: 'ProductCategory',
    to: `/ProductCategory`,
    icon: 'mdi-view-grid-outline',
  },
  {
    title: '產品 | Product',
    key: 'PhysicalProducts',
    to: `/PhysicalProducts`,
    icon: 'mdi-lotion',
  },
  {
    title: '折扣 | Discount',
    key: 'discount',
    to: `/discount`,
    icon: 'mdi-sale',
  },
  {
    title: '交易 | Transaction',
    key: 'transaction',
    to: `/transaction`,
    icon: 'mdi-list-box',
  },
  { divider: true },
  {
    title: '打卡紀錄 | Clock-in Records',
    key: 'clockInRecords',
    to: `/clockInRecords`,
    icon: 'mdi-clock',
  },
];
