const state = {
  hasUnreadNotification: false,
};

const mutations = {
  setHasUnreadNotification(state, val) {
    state.hasUnreadNotification = val;
  },
};

const actions = {};

const getters = {
  hasUnreadNotification: state => state.hasUnreadNotification,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
