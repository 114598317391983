<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-card elevation="5" width="70%">
      <v-card-title>
        <span class="mainTitle" style="font-size: 20px">Login</span>
      </v-card-title>
      <v-form @submit.prevent="submit" ref="form">
        <v-container>
          <v-row>
            <v-col cols="12">
              <custom-label item-field-name="Login Account" />
              <v-text-field
                :prepend-inner-icon="'mdi-account-box'"
                v-model="account"
                outlined
                :rules="rules"
              />
            </v-col>
            <v-col cols="12">
              <custom-label item-field-name="Password" />
              <v-text-field
                v-model="password"
                outlined
                :prepend-inner-icon="'mdi-key'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passRules"
                :type="show ? 'text' : 'password'"
                required
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="d-flex justify-space-between align-center">
            <v-checkbox label="Remember me" v-model="remember" />
            <v-btn type="submit" color="primary"> Login </v-btn>
          </div>
          <div v-if="showErr" class="errMessage text-center">{{ showErr }}</div>

          <div class="text-right">
            <v-btn @click="forget" text color="itemLabel  ">Forget password?</v-btn>
          </div>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import api from '@/api';
import { ssoLogin } from '@/api/auth/sso';
import { mapActions } from 'vuex';
import CustomLabel from '../customFormLabel/customLabel';

export default {
  components: { CustomLabel },
  props: {
    ret: {},
  },
  data() {
    return {
      account: '',
      rules: [
        value => !!value || 'Required.',
        //value => (value && value.length >= 1) || 'Min 1 characters',
      ],
      show: false,
      check: false,
      password: '',
      remember: true,
      showErr: null,
      passRules: [
        value => !!value || 'Required.',
        //value => (value && value.length >= 8) || 'Min  characters',
      ],
    };
  },
  methods: {
    ...mapActions('auth', ['checkLoginStatus']),
    async submit() {
      try {
        this.$refs.form.validate();
        const Credential = {
          username: this.account,
          // auto: this.account, //TODO: fix NGT login auto field with unexpected error “More than one user matches with the provided credentials”
          pw: this.password,
          remember: this.remember,
        };
        await ssoLogin(api, Credential);
        await this.checkLoginStatus();
        await this.$router.push(this.ret || '/').catch(() => {});
      } catch (err) {
        switch (err.message) {
          case 'Invalid Credentials': {
            this.showErr = 'Incorrect Password';
            break;
          }
          case 'Missing credentials': {
            if (this.account === '') this.showErr = 'Please enter username and password ';
            else this.showErr = 'Please enter password ';
            break;
          }
          default:
            this.showErr = err.message;
        }
      }
    },
    forget() {
      this.$router.replace({ name: 'forgetpassword' }).catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.errMessage {
  font-weight: 600;
  color: var(--v-danger-base);
}
</style>
