<template>
  <v-app>
    <v-alert class="popAlert" v-if="displayAlert" :type="alertType" :color="color">
      <div style="text-align: center">{{ alertMessage }}</div>
      <template v-slot:close>
        <v-icon @click="closeAlert"> mdi-close</v-icon>
      </template>
    </v-alert>
    <v-navigation-drawer
      touchless
      permanent
      expand-on-hover
      v-model="drawer"
      app
      color="secondary"
      dark
    >
      <drawer />
    </v-navigation-drawer>
    <CustomDialog ref="confirm"></CustomDialog>
    <v-app-bar app flat class="whatsappGrey" dense>
      <!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />-->
      <img src="@/assets/TocaLogo.svg" style="width: 30px; height: 30px; padding: 2.5px" />
      <!--      <v-toolbar-title>Title</v-toolbar-title> -->
      <!--      <v-menu bottom right>-->
      <!--        <template v-slot:activator="{ on, attrs }">-->
      <!--          <v-btn icon color="inherit" v-bind="attrs" v-on="on">-->
      <!--            <v-icon>mdi-dots-vertical</v-icon>-->
      <!--          </v-btn>-->
      <!--        </template>-->

      <!--        <v-list>-->
      <!--          <v-list-item v-for="(item, i) in items" :key="i">-->
      <!--            <v-list-item-title>{{ item.title }}</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--        </v-list>-->
      <!--      </v-menu>-->
      <v-spacer />
      <div class="d-flex align-center">
        <small v-if="debug">DEBUG MODE </small>
        <small @click="triggerDebugMode">{{ buildVersion }}</small>
      </div>
      <v-spacer />
      <div class="pr-sm-3 d-flex align-center">
        <h3>
          {{ profile && profile.name }} (<span style="text-transform: capitalize">{{ role }}</span
          >)
        </h3>
      </div>
      <a @click="myProfile" class="mr-sm-8">
        <v-icon style="font-size: 30px"> mdi-account</v-icon>
      </a>
      <a @click="ssoLogout" v-if="isLoggedIn">
        <v-icon style="font-size: 30px"> mdi-logout-variant</v-icon>
      </a>
      <a v-else>
        <v-icon style="font-size: 30px"> mdi-login-variant</v-icon>
      </a>
    </v-app-bar>
    <v-main>
      <BlockedView v-if="loginBlocked" class="text-center">
        <div style="background-color: red; padding: 50px; border-radius: 100px">
          <h2 style="color: white; text-align: center; margin-bottom: 20px">
            Your working has been blocked
            <br />
            Please contact your manager
            <br />
            <template>
              <h3 v-if="loginBlocked.subcode === 'needApproval'">
                Your account need to be approve
              </h3>
              <h3 v-if="loginBlocked.subcode === 'workTimePeriod'">
                Your work time period expired
              </h3>
            </template>
          </h2>
          <v-btn @click="ssoLogout"> Got it</v-btn>
        </div>
      </BlockedView>
      <template v-else>
        <router-view :key="$route.fullPath" />
      </template>
    </v-main>
  </v-app>
</template>

<script>
import api from '@/api';
import { ssoLogout } from '@/api/auth/sso';
import BlockedView from '@/components/BlockedView';
import Drawer from '@/layout/partial/drawerNav';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CustomDialog from '@/components/CustomDialog';

const passcode = 'fletrix';

export default {
  components: { BlockedView, Drawer, CustomDialog },
  data() {
    return {
      loginBlockedMessage: '',
      drawer: false,
      clickCount: 0,
    };
  },
  computed: {
    ...mapGetters('alert', ['alertType', 'displayAlert', 'alertMessage', 'color']),
    ...mapGetters('auth', ['profile', 'role', 'isLoggedIn', 'loginBlocked']),
    ...mapGetters(['debug']),
    buildVersion() {
      const { time, git } = process.env.BUILD;
      return `ver: ${git.version}-${git.branch} (${moment(time).format('YYYY-MM-DD HH:mm')})`;
    },
  },
  watch: {
    loginBlocked() {
      if (this.loginBlocked) this.dialog = true;
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
  methods: {
    ...mapActions('alert', ['closeAlert']),
    ...mapActions('auth', ['checkLoginStatus']),
    ...mapMutations(['setDebug']),
    myProfile() {
      this.$router.push({ name: 'myProfile' }).catch(() => {});
    },
    async ssoLogout() {
      if (!confirm('Do you confirm to logout?')) return;
      await ssoLogout(api);
      try {
        await this.checkLoginStatus();
      } catch (e) {}
      await this.$router.push({ name: 'login' });
    },
    triggerDebugMode() {
      this.clickCount += 1;
      if (this.clickCount > 4) {
        const pw = window.prompt('enter passcode');
        if (pw === passcode) this.setDebug(true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog--active {
  border-radius: 20px;
}

.v-main {
  height: 100vh;
}

.popAlert {
  position: fixed;
  bottom: 0px;
  z-index: 900;
  width: 60%;
  left: calc((100% - 60%) / 2);
}
</style>
