import ApiError from '@/api/ApiError';
import store from '@/store';
import axios, { Axios } from 'axios';
import mergeConfig from 'axios/lib/core/mergeConfig';
import Cookies from 'js-cookie';

/**
 * @this {AxiosInstance}
 * @param {AxiosRequestConfig} [config]
 * @return {AxiosInstance} inherited instance
 */
Axios.prototype.extend = function (config) {
  const instance = axios.create(mergeConfig(this.defaults, config));
  instance.interceptors.request.handlers = this.interceptors.request.handlers.slice();
  instance.interceptors.response.handlers = this.interceptors.response.handlers.slice();
  return instance;
};

export function createInstance() {
  const instance = axios.create({
    // url = base url + request url
    get baseURL() {
      return this.host + this.base;
    },
    get base() {
      return '/api';
    },
    get host() {
      return Cookies.get('apihost') || process.env.VUE_APP_API_HOST;
    },
    withCredentials: true, // send cookies when cross-domain requests
    timeout: process.env.NODE_ENV === 'production' ? 300000 : 0, // request timeout // Handle report download
    validateStatus: null, // treat all response as success, we will handle it manually
  });

  // request interceptor
  instance.interceptors.request.use(
    async config => {
      // shallow serialize params
      {
        const p = config.params;
        if (p && typeof p === 'object') {
          const params = {};
          for (const [k, v] of Object.entries(p)) {
            if (typeof v !== 'string' && typeof v !== 'undefined') {
              params[k] = JSON.stringify(v);
            } else {
              params[k] = v;
            }
          }
          config.params = params;
        }
      }

      // account impersonation
      if (store.getters['auth/impersonatedToken']) {
        config.headers['X-Auth-Impersonate'] = store.getters['auth/impersonatedToken'];
      }

      // throttle network for debugging UX
      if (process.env.VUE_APP_THROTTLE_NETWORK) {
        await new Promise(cb =>
          setTimeout(cb, Math.random() * parseInt(process.env.VUE_APP_THROTTLE_NETWORK)),
        );
      }

      return config;
    },
    error => {
      // do something with request error
      // console.error(error); // for debug
      return Promise.reject(error);
    },
  );

  // response interceptor
  instance.interceptors.response.use(
    response => {
      if (response.status >= 400 || (response.data && response.data.error)) {
        if (response.data instanceof Blob) {
          return response;
        }

        const ae = ApiError.wrap(response);
        if (ae.code === 'invalid_booking_time') {
          ae.message =
            'Selected timeslot is not available, for follow up please contact administrator';
        }
        if (ae.code === 'invalid_booking_status') {
          ae.message = 'Booking is already paid';
        }
        // if (ae.code === 'invalid_status') {
        //   ae.message = 'invalid_status_time_in_future';
        // }
        if (ae.code === 'invalid_status_time_in_future' || ae.code === 'cannot_assign_timetable') {
          ae.message = 'Please select other time slot or contact administrator';
        }
        if (ae.code === 'invalid_ticketPackage') {
          ae.message = 'invalid ticketPackage';
        }
        if (ae.code === 'invalid_serviceProductPackage') {
          ae.message = 'The service product does not have the specified package';
        }
        if (ae.code === 'profile_have_no_user') {
          ae.message = 'profile have no user';
        }
        if (ae.code === 'account_blocked') {
          ae.message = 'Please contact the administrator';
        }
        if (ae.code === 'booking_not_completed') {
          ae.message = 'You have unfinished booking';
        }
        if (ae.code === 'booking_paid') {
          ae.message = 'Booking is paid';
        }
        if (ae.code === 'invalid_sharedProfile') {
          ae.message = 'Cannot share ticket to yourself';
        }
        return Promise.reject(ae);
      } else {
        return response;
      }
    },
    error => {
      console.error(error); // for debug
      return Promise.reject(error);
    },
  );
  return instance;
}

/**
 * @type {AxiosInstance}
 */
const service = createInstance();

export default service;
