import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export const defaults = Object.freeze({
  primary: '#6C7FEE',
  primary_inverse: '#FFFFFF',
  secondary: '#081C35',
  secondary_inverse: '#FFFFFF',
  success: '#8DDC88',
  info: '#71BAFF',
  danger: '#F8706D',
  warning: '#FFD652',
  accent: '#82B1FF',
  error: '#FF5252',
  itemLabel: '#919191',
  mainTitleLabel: '#081C35',
  disabled: '#bfbdbd',
  green: '#E1551A',
  muted: '#cccccc',
  lightGreen: '#9CD291',
  greenTextColor: '#4E8939',
});

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: { ...defaults },
    },
  },
});
