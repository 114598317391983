/**
 * @param {AxiosInstance} api
 * @return {Promise<{user: User | boolean, profile?: Profile, session?: object}>}
 */
export async function ssoCheckLoginStatus(api) {
  return (await api.get('sso/checkLoginStatus')).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{auto?: string, pw?: string, username?: string, email?: string, remember?: boolean}} credentials
 * @return {Promise<{user: User, accessToken: object, session: {expires: number, remember: boolean}}>}
 */
export async function ssoLogin(api, credentials) {
  return (await api.post('sso/login', credentials)).data;
}

/**
 * @param {AxiosInstance} api
 * @return {Promise<void>}
 */
export async function ssoLogout(api) {
  await api.post('sso/logout');
}

/**
 * @param {AxiosInstance} api
 * @param {string} profileId
 * @return {Promise<void>}
 */
export async function ssoSwitchProfile(api, profileId) {
  await api.post(`sso/switch/${profileId}`);
}

/**
 * @param {AxiosInstance} api
 * @param {string} userId
 * @param {boolean} [link]
 * @return {Promise<void>}
 */
export async function resetPassword(api, userId, link) {
  return (await api.post(`users/${userId}/requestChangePassword`, { link_only: link })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} email
 * @return {Promise<void>}
 */
export async function forgetPassword(api, email) {
  return await api.post('user/forgotPassword', email);
}

/**
 * @param {AxiosInstance} api
 * @param {{token: string}} token
 * @param {string} password
 * @return {Promise<void>}
 */
export async function resetPasswordByToken(api, password, token) {
  return await api.post('user/resetPassword', { newPw: password }, { params: token });
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {Promise<void>}
 */
export async function createLoginToken(api, id) {
  return (await api.get(`toca/admin/clientProfile/createLoginToken/${id}`)).data;
}
