import vuetify, { defaults } from '@/plugins/vuetify';
import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import notification from './modules/notification';
import alert from '@/store/modules/alert';
import Cookies from 'js-cookie';
import api from '@/api';
import { checkIfHasUnreadNotification } from '@/api/notification';
import dateFilter from './modules/dateFilter';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    debug: !!Cookies.get('debug'),
  },
  mutations: {
    setDebug(state, val) {
      state.debug = val;
    },
  },
  actions: {},
  getters: {
    debug: state => state.debug,
  },
  modules: {
    auth,
    alert,
    notification,
    dateFilter,
  },
});

export default store;

//** Moved to Route **
// store.watch(
//   (_, getters) => getters['auth/role'],
//   v => {
//     switch (v) {
//       case 'admin':
//         vuetify.framework.theme.themes.light.primary = '#619437';
//         break;
//       case 'toca.cashier':
//         vuetify.framework.theme.themes.light.primary = '#ff9505';
//         break;
//       case 'toca.master':
//         vuetify.framework.theme.themes.light.primary = '#179eeb';
//         break;
//       default:
//         vuetify.framework.theme.themes.light = { ...defaults };
//     }
//   },
// );

store.watch(
  (_, getters) => getters['auth/role'],
  async x => {
    store.commit(
      'notification/setHasUnreadNotification',
      x ? await checkIfHasUnreadNotification(api) : null,
    );
  },
);
