import api from '@/api';
import { getOid } from '@/services/utils';
import { parsePhoneNumber } from 'libphonenumber-js';
import { get } from 'lodash';
import _moment from 'moment';
import { physicalProductOrderStatusTextTranslation, transactionRefs } from '@/data/constants';

let dateFormat = 'YYYY/MM/DD (ddd)';
let datetimeFormat = 'YYYY/MM/DD (ddd) HH:mm';
let timeFormat = 'HH:mm';
let defaultDecimals = 2;
let defaultDollarSign = '$';

export { dateFormat, datetimeFormat, timeFormat, defaultDecimals, defaultDollarSign };

export function getId(v) {
  return getOid(v);
}

export function moment(v, format = datetimeFormat) {
  if (v == null) {
    return '-';
  }
  return _moment(v).format(format);
}

export function calendarDate(v, longFormat = dateFormat, now = new Date()) {
  if (v == null) {
    return '';
  }
  const diff = +_moment(v).startOf('d').diff(_moment(now).startOf('d'), 'days');
  if (diff === 0) {
    return 'Today';
  } else if (diff > 0 && diff <= 1) {
    return 'Tomorrow';
  } else if (diff < 0 && diff >= -1) {
    return 'Yesterday';
  } else {
    return _moment(v).format(longFormat);
  }
}

export function duration(v, format) {
  if (v == null) {
    return '';
  }
  return _moment.duration(v).format(format);
}

export function datePrettify(v, longFormat = datetimeFormat, now = new Date()) {
  if (v == null) {
    return '';
  }
  v = _moment(v);
  return Math.abs(v.diff(now, 'day', true)) > 2 ? v.format(longFormat) : v.from(now);
}

export function time(v) {
  return moment(v, timeFormat);
}

function parseDateRange(v, startProp, endProp) {
  if (v == null) {
    return false;
  }
  const s = _moment(get(v, startProp, null));
  const e = _moment(get(v, endProp, null)).add(-1, 'ms');
  return {
    s: s.isValid() ? s : null,
    e: e.isValid() ? e : null,
  };
}

export function dateRange(v, startProp = 'start', endProp = 'end', pretty = true) {
  const { s, e } = parseDateRange(v, startProp, endProp);
  if (!s && !e) {
    return '';
  }
  const sD = s ? s.format(dateFormat) : '';
  const eD = e ? e.format(dateFormat) : '';
  if (pretty && sD === eD && sD) {
    return sD;
  }
  return `${sD} - ${eD}`;
}

export function timeRange(v, startProp = 'start', endProp = 'end') {
  const { s, e } = parseDateRange(v, startProp, endProp);
  if (!s && !e) {
    return '';
  }
  const sT = s ? s.format(timeFormat) : '';
  const eT = e ? e.format(timeFormat) : '';
  return `${sT} - ${eT}`;
}

export function datetimeRange(v, startProp = 'start', endProp = 'end', pretty = true) {
  const { s, e } = parseDateRange(v, startProp, endProp);
  if (!s && !e) {
    return '';
  }
  const sD = s ? s.format(dateFormat) : '';
  const eD = e ? e.format(dateFormat) : '';
  // const sT = s ? s.format(timeFormat) : ''
  const eT = e ? e.format(timeFormat) : '';
  const sDT = s ? s.format(datetimeFormat) : '';
  const eDT = e ? e.format(datetimeFormat) : '';
  const exact = `${sDT} - ${eDT}`;
  let short = sD;
  if (sD !== eD) {
    short = exact;
  } else {
    short = `${sDT} - ${eT}`;
  }
  return pretty ? short : exact;
}

export function currency(
  v,
  dollar = defaultDollarSign,
  decimals = defaultDecimals,
  accountingFormat = false,
) {
  if (v == null) {
    return '';
  }
  const display =
    dollar +
    Math.abs(v)
      .toFixed(decimals)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  if (accountingFormat) {
    return signed(v, '({v})', ' {v} ', undefined, display);
  }
  return signed(v, undefined, undefined, undefined, display);
}

export function amount(
  v,
  dollar = defaultDollarSign,
  decimals = defaultDecimals,
  accountingFormat = false,
) {
  if (v == null) {
    return '';
  }
  const prefix = v.currency
    ? Vue.i18n.translate(
        `currencies.${v.currency.toLowerCase()}.shortcode`,
        v.currency.toUpperCase() + dollar,
      )
    : '';
  return currency(v.value, prefix, decimals, accountingFormat);
}

/**
 * format a value with specific +ve/-ve/0 templates
 * @param {*} value numeric value
 * @param {string} negative template
 * @param {string} positive template
 * @param {string} zero template
 * @param {string|*} displayValue string value to substitute
 * @returns {string|*}
 */
export function signed(
  value,
  negative = '-{v}',
  positive = '{v}',
  zero = '{v}',
  displayValue = Math.abs(value),
) {
  const sign = parseFloat(value);
  if (isNaN(sign)) {
    return value;
  }
  const template = sign === 0 ? zero : sign < 0 ? negative : positive;
  return template.replace(/{v}/g, String(displayValue));
}

export function round(v, decimals = defaultDecimals) {
  if (v == null) {
    return '';
  }
  const order = Math.pow(10, decimals);
  return Math.round(order * v) / order;
}

export function siUnit(value, units, zeroIndex, base, decimals = defaultDecimals) {
  decimals = Math.pow(10, decimals);
  let order = Math.floor(Math.log(value) / Math.log(base));
  order = Math.min(Math.max(order + zeroIndex, 0), units.length - 1) - zeroIndex;
  const round = Math.round((value / Math.pow(base, order)) * decimals) / decimals;
  return `${round} ${units[order + zeroIndex]}`;
}

export function byte(value, decimals = defaultDecimals) {
  return siUnit(value, ['B', 'KB', 'MB', 'GB', 'TB'], 0, 1024, decimals);
}

export function objectId(v) {
  if (v == null) {
    return '';
  }
  return v.substr(-6).toUpperCase();
}

export function status(v) {
  if (v == null) {
    return '';
  }
  v = v.split('$').reverse()[0];
  // return Vue.i18n.translate('statuses.' + v, v[0].toUpperCase() + v.slice(1));
  return v[0].toUpperCase() + v.slice(1);
}

export function transBoolean(v) {
  if (v == null) {
    return '';
  }
  return v ? 'Yes' : 'No';
}

export function capitalize(v) {
  if (!v) {
    return v;
  }
  v = String(v);
  return v.charAt(0).toUpperCase() + v.slice(1);
}

export function decapitalize(v) {
  if (!v) {
    return v;
  }
  v = String(v);
  return v.charAt(0).toLowerCase() + v.slice(1);
}

export function attnDownloadHref(id) {
  id = getId(id);
  const { host, base } = api.defaults;
  return `${host}${base}/attachments/${id}/download`;
}

export function attnThumbHref(id, size) {
  id = getId(id);
  size = size ? '?size=' + size : '';
  const { host, base } = api.defaults;
  return `${host}${base}/attachments/${id}/thumbnail/${size}`;
}

const availableSize = ['64', '128', '256', '512', 'hd', 'fhd', '2k'];

export function getThumbnail($axios, id, size = null) {
  return (
    $axios.defaults.baseURL +
    `/attachments/${getId(id)}/thumbnail${
      size && availableSize.includes(String(size)) ? `?size=${size}` : ''
    }`
  );
}

export function profileDisplayName(p, showPhone = false) {
  if (!p) return null;
  let phone = showPhone && displayPhone(p.phone);
  if (phone) {
    return [phone, p.name && `(${p.name})`].filter(x => x).join(' ');
  } else {
    return p.name;
  }
}

export function displayPhone(v, formatter = x => x.formatNational()) {
  if (typeof v !== 'string' || v?.length === 0) return null;
  // if phone number contains multiple area codes
  const regex = /(\+\d*)/g;
  let phone = v?.replace(/\s/g, '')?.match(regex)?.slice(-1)?.[0]
  
  try {
    phone = parsePhoneNumber(phone)
    return phone?.number
  } catch (error) {
    return v
  }
}

export function translateOptions(v, options, itemLabel = 'label') {
  if (!v) return null;
  if (!options) return null;
  let out;
  out = options.find(x => x.value === v);
  return out?.[itemLabel] || v;
}

export function parseHKPhone(v) {
  return typeof v === 'string' ? v.replace('+852', '') : v;
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

/**
 * 10000 => "10,000"
 * @param {string} transactionRef
 * @returns {string} -BookingId
 */
export function getBookingIdFromTransactionRef(transactionRef) {
  if (!transactionRef || !(typeof transactionRef === 'string')) return null;
  const x = transactionRef.split(':');
  return Object.values(transactionRefs).includes(x?.[0]) ? x?.[1] : '-';
}

export function translatePhysicalProductOrderStatus(v) {
  const status = v.split('$')[1];
  const text = physicalProductOrderStatusTextTranslation[status];
  return text;
}

/**
 * @param {IProfile} profile
 * @returns {string}
 */
export function getNameFromProfile(profile) {
  const name = get(profile, 'name');
  const lastName = get(profile, 'name_en.lastName');
  const firstName = get(profile, 'name_en.firstName');
  const name_en = (!!lastName || !!firstName) && lastName + ' ' + firstName;
  const name_zh = get(profile, 'name_zh');
  return name || name_zh || name_en || '-';
}

export function getVIPPackageInfo(VIPPackage) {
  const name = get(VIPPackage, 'name');
  const sid = get(VIPPackage, 'sid');
  const price = get(VIPPackage, 'discountedPrice');
  return `${name}\xa0\xa0\xa0Sid: ${sid}\xa0\xa0\xa0Price: ${price}` || '-';
}

export function parseAndLocalize(v) {
  // TODO: use i18n locale if needed
  try {
    return JSON?.parse(v)?.en || v;
  } catch (error) {
    return v;
  }
}

export function paymentMethod(v) {
  return v.charAt(0).toUpperCase() + v.slice(1).replace('_', ' ');
}
