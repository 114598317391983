<template>
  <v-list>
    <template v-for="{ to, key, icon, title, children, divider, inset } in routes">
      <v-divider v-if="divider" :inset="inset" style="border-color: var(--v-primary-base)" />
      <v-list-group v-else-if="children" :key="key">
        <template v-slot:activator>
          <v-list-item-action>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </template>
        <v-list-item
          v-for="child in children"
          :key="child.key"
          :to="child.to"
          :exact="true"
          color="primary"
        >
          <v-list-item-action>
            <span class="full-width text-center">{{ child.textIcon }}</span>
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="child.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item
        v-else
        :key="key"
        :to="to"
        :exact="true"
        :class="{ 'v-list-item--active': $route.name === key }"
        color="primary"
      >
        <v-list-item-action>
          <v-badge
            v-if="hasUnreadNotification && title === 'Notification'"
            color="red"
            dot
          ></v-badge>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="title" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { canAccessRoute } from '@/router';
import { getMenuData } from '@/services/menu';
import { mapGetters } from 'vuex';
import store from '@/store';

export default {
  name: 'drawerNav',
  computed: {
    ...mapGetters('auth', ['role']),
    ...mapGetters('notification', ['hasUnreadNotification']),
    routes() {
      const filter = arr =>
        arr
          .map(x => {
            if (x.children) {
              x = { ...x, children: filter(x.children) };
              x.children = x.children.filter(
                x => !x.auth || x.auth.includes(store.getters['auth/role']),
              );
              if (x.children.length) return x;
            } else if (x.to != null) {
              return canAccessRoute(x.to) && x;
            } else {
              return x;
            }
          })
          .filter(x => x);
      return filter(getMenuData);
    },
  },
};
</script>

<style scoped>
.v-list-item--active {
  /* if needed style update here */
}
</style>
